<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单类型">
              <a-select
                v-model="queryParam.order_type"
                style="width: 100%"
                placeholder="请选择订单类型"
                @change="loadData"
              >
                <a-select-option v-for="op in orderTypeData" :key="op.value">
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单号">
              <a-input v-model.trim="queryParam.order_no" placeholder="根据订单号查询" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="退款状态">
              <a-select
                v-model="queryParam.apply_refund_status"
                style="width: 100%"
                placeholder="请选择退款申请状态"
                allowClear
                @change="loadData"
              >
                <a-select-option v-for="op in refundStatusData" :key="op.value">
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="提交财商审核时间">
              <a-range-picker @change="applyAuditDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="退款时间">
              <a-range-picker @change="refundDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="原支付流水号">
              <a-input v-model.trim="queryParam.old_bill_no" placeholder="根据原支付流水号查询" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="原通联支付流水号">
              <a-input v-model.trim="queryParam.old_trx_id" placeholder="根据原通联支付流水号查询" allowClear />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="queryList" style="margin-right: 10px">查询</a-button>
      <a-button type="primary" @click="applyRefundAuditCheck" :disabled="!this.selectedRowKeys.length">提交财商审核</a-button>
      <a-button type="primary" @click="cancelRefundApply" :disabled="!this.selectedRowKeys.length">撤销退款申请</a-button>
    </div>

    <a-table
      size="small"
      row-key="id"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="handleChangeTable"
      :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
    >
      <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
      <template slot="applyRefundStatus" slot-scope="text">
        <a-tag :color="applyRefundStatusColor(text)">
          {{ refundStatusData[text].name }}
        </a-tag>
      </template>
      <template slot="refundCashData" slot-scope="text">
        <div v-for="op in text" :key="op.id">
          <span style="font-weight: bold;">原支付流水号：</span>{{ op.old_bill_no }} <br>
          <span style="font-weight: bold;">原通联支付流水：</span>{{ op.old_trx_id }} <br>
          <span style="font-weight: bold;">原支付金额：</span>{{ op.old_bill_amount }} <br>
          <span v-if="op.refund_result">
            <span style="font-weight: bold;">退款结果说明：</span>{{ op.refund_result }} <br>
          </span>
          <span v-if="op.refund_time">
            <span style="font-weight: bold;">通联退款时间：</span>{{ op.refund_time }} <br>
          </span>
          <span v-if="op.refund_amount">
            <span style="font-weight: bold;">通联退款金额：</span>{{ op.refund_amount }} <br>
          </span>
        </div>
      </template>
      <template slot="refundGoodData" slot-scope="text">
        <div v-for="(op, index) in text" :key="index">
          <span style="font-weight: bold;">商品：</span>{{ op.good_info }} <br>
          <span style="font-weight: bold;">卖出数量：</span>{{ op.good_num }}
          <span style="font-weight: bold;">卖出价格：</span>{{ op.price_out }}
          <span style="font-weight: bold;">退款数量：</span>{{ op.refund_num }}
        </div>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import {
  pageRefundApply,
  applyRefundAuditCheck,
  cancelRefundApply
} from '@/api/wms/sale'
import moment from 'moment'

export default {
  name: 'RefundApplyData',
  data() {
    return {
      queryParam: {
        page: 1,
        pageSize: 10,
        order_type: 1
      },
      orderTypeData: [
        { value: 1, name: '出货' },
        { value: 2, name: '代销' }
      ],
      refundStatusData: [
        { value: '0', name: '业务待审核' },
        { value: '1', name: '财商待审核' },
        { value: '2', name: '退款完成' },
        { value: '3', name: '财商驳回' },
        { value: '4', name: '退款失败' }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单类型',
          dataIndex: 'order_type',
          customRender: (text) => {
            if (text === 1) {
              return '出货'
            } else if (text === 2) {
              return '代销'
            }
          }
        },
        {
          title: '订单号',
          dataIndex: 'order_no'
        },
        {
          title: '退款时间',
          dataIndex: 'refund_time',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '退款金额',
          dataIndex: 'apply_refund_amount'
        },
        {
          title: '退款状态',
          dataIndex: 'apply_refund_status',
          scopedSlots: { customRender: 'applyRefundStatus' }
        },
        {
          title: '退款明细',
          dataIndex: 'refund_cash_list',
          scopedSlots: { customRender: 'refundCashData' }
        },
        {
          title: '退款商品明细',
          dataIndex: 'refund_good_list',
          scopedSlots: { customRender: 'refundGoodData' }
        },
        {
          title: '退款原因',
          dataIndex: 'apply_refund_reason'
        },
        {
          title: '业务审核人',
          dataIndex: 'check_user_name'
        },
        {
          title: '提交财商审核时间',
          dataIndex: 'apply_time',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '财商审核人员',
          dataIndex: 'finance_user_name'
        },
        {
          title: '退款结果说明',
          dataIndex: 'refund_result'
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      pageRefundApply(this.queryParam).then(res => {
        this.pagination.total = res.total
        this.data = res.list
      })
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['pageSize'] = page.pageSize
      this.loadData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['pageSize'] = 10
      this.loadData()
    },
    // 提交财商审核
    applyRefundAuditCheck() {
      let flag = false
      this.selectedRows.forEach(item => {
        if (item.apply_refund_status === '0' || item.apply_refund_status === '3') {
          flag = true
        }
      })

      if (flag) {
        const reqParam = {
          'id_list': this.selectedRows.map(item => item.id)
        }
        applyRefundAuditCheck(reqParam).then(res => {
          this.$notification['success']({
            message: '提示',
            description: '提交审核成功'
          })
        }).finally(() => {
          this.loadData()
          this.selectedRowKeys = []
          this.selectedRows = []
        })
      } else {
        this.$notification['error']({
          message: '提示',
          description: '只能提交待审核或被财商驳回的记录'
        })
      }
    },
    // 撤销退款申请
    cancelRefundApply() {
      this.$confirm({
        title: '确认提示',
        content: '确定要撤销退款申请?',
        onOk: () => {
          let flag = false
          this.selectedRows.forEach(item => {
            if (item.apply_refund_status === '0' || item.apply_refund_status === '3') {
              flag = true
            }
          })

          if (flag) {
            const reqParam = {
              'id_list': this.selectedRows.map(item => item.id)
            }
            cancelRefundApply(reqParam).then(res => {
              this.$notification['success']({
                message: '提示',
                description: '撤销成功'
              })
            }).finally(_ => {
              this.loadData()
              this.selectedRowKeys = []
              this.selectedRows = []
            })
          } else {
            this.$notification['error']({
              message: '提示',
              description: '只能撤销待审核或被财商驳回的记录'
            })
          }
        }
      })
    },
    applyRefundStatusColor(text) {
      if (text === '0') {
        return '#faad14'
      } else if (text === '1') {
        return '#1890ff'
      } else if (text === '2') {
        return '#4cd964'
      } else if (text === '3') {
        return '#ff4d4f'
      } else if (text === '4') {
        return '#ff4d4f'
      }
    },
    applyAuditDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['apply_start_time'] = startDate
        this.queryParam['apply_end_time'] = endDate
      } else {
        this.queryParam['apply_start_time'] = null
        this.queryParam['apply_end_time'] = null
      }
    },
    refundDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['refund_start_time'] = startDate
        this.queryParam['refund_end_time'] = endDate
      } else {
        this.queryParam['refund_start_time'] = null
        this.queryParam['refund_end_time'] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
